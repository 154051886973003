import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import Favicon from "../images/logos/Gogreen-favicon.png"

const CommercialSubmission = () => {
  const [sectorForm, setSectorForm] = useState({
    bizSector: "",
    bizName: "",
    bizRepName: "",
    bizRepEmail: "",
    bizPhone: "",
  });

  const [SubmitDisabled, setSubmitDisabled] = useState(false);

  function handleChange(e) {
    setSectorForm({
      ...sectorForm,
      [e.target.name]: e.target.value,
    });
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    setSubmitDisabled(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Commercial Submission", ...sectorForm }),
    })
      .then(() => window.open("../thank-you-general", "_self"))
      .catch(() => window.open("../Error", "_self"));

    e.preventDefault();
  };

  return (
    <>
      <main class="relative bg-gradient-to-b from-green-50 to-white">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Go Green Credits | Commercial</title>

          <meta
            name="description"
            content="Go Green can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green! "
          />
          <link
            rel="icon"
            href={Favicon}
          />
        </Helmet>
        <div class="hidden md:block">
          <Header />
        </div>
        <div class="md:hidden pb-2 z-10 bg-white  absolute  top-0 sticky">
          <Header />
        </div>
        <div>
          <div class="mb-10 hero px-4 md:px-0 mt-4 items-center pb-2 lg:overflow-hidden">
            <div class="mx-auto max-w-7xl">
              <div class="flex justify-center items-center">
                <div class="md:w-1/2">
                  <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    Commercial Inquiry
                  </h1>
                  <p class="mt-3 text-base text-gray-600 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                    Manufacturing plants, industrial warehouses, and similar
                    types of larger commercial buildings produce high volumes of
                    CO2. For us to be able to accurately calculate the carbon
                    production of your facility we'll need additional
                    information. Fill out the form below and one of our Carbon
                    offset specialists will contact your business directly.
                  </p>{" "}
                  <form
                    method="POST"
                    target="_self"
                    id="Commercial Submission"
                    name="Commercial Submission"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                    class="form"
                  >
                    <input
                      name="bizName"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      required
                      type="text"
                      class="mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                      placeholder="Business Name"
                    />
                    <input
                      name="bizRepName"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      required
                      type="text"
                      class="mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                      placeholder="Full Name"
                    />
                    <input
                      name="bizRepEmail"
                      type="email"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      required
                      class="mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                      placeholder="Rep Email"
                    />
                    <input
                      name="bizRepEmails"
                      type="email"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      class="mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                      placeholder="Rep Emails"
                    />

                    <div class="mt-10">
                      {" "}
                      <button
                        type="submit"
                        class=" w-full flex items-center justify-center px-10 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10 rounded-md "
                      >
                        <span class="">Submit </span>
                      </button>
                    </div>
                  </form>
                </div>
                <div class="hidden lg:block pl-10 w-1/2  relative">
                  <img
                    class="mx-auto rounded-lg"
                    src="https://imgix.cosmicjs.com/d0462570-792d-11ec-bc8e-0b22aad4e2bd-hero-plug.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </main>

      <Footer />
    </>
  );
};

export default CommercialSubmission;
